const Navbar = () => {
  return (
    <h1 className="logo">
      Youtopia<i className="fa fa-futbol-o fa-2x" aria-hidden="true"></i>
      Sports
    </h1>
  );
};

export default Navbar;
