import { useState, useEffect, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import io from "socket.io-client";
import useSocketSetup from "../customHook/useSocketSetup";
import Input from "./input";
import Select from "./select";
import Goalmodal from "./goalModal";
import Yellowmodal from "./yellowModal";
import Redmodal from "./redModal";

const Live = () => {
  const { datas, setDatas } = useSocketSetup();
  const [modalIsOpen1, setModalIsOpen1] = useState(false);
  const [modalIsOpen2, setModalIsOpen2] = useState(false);
  const [modalIsOpen3, setModalIsOpen3] = useState(false);
  const navigate = useNavigate();

  const openModal1 = () => setModalIsOpen1(true);
  const openModal2 = () => setModalIsOpen2(true);
  const openModal3 = () => setModalIsOpen3(true);
  const closeModal1 = () => setModalIsOpen1(false);
  const closeModal2 = () => setModalIsOpen2(false);
  const closeModal3 = () => setModalIsOpen3(false);

  const { id } = useParams();
  const [data, setData] = useState({
    round: "",
    matchnum: "",
    homeClubID: "",
    homeGoal: "",
    awayClubID: "",
    awayGoal: "",
    date: "",
    time: "",
    status: "",
    stadium: "",
    homeShots: "",
    homeTargetshots: "",
    homePossession: "",
    homeCorners: "",
    homeOffside: "",
    homePasses: "",
    homeFouls: "",
    awayShots: "",
    awayTargetshots: "",
    awayPossession: "",
    awayCorners: "",
    awayOffside: "",
    awayPasses: "",
    awayFouls: "",
  });

  useEffect(() => {
    const getUpdate = () => {
      if (id === "new") return;
      if (Array.isArray(datas) && datas.length > 0) {
        const match = datas.find((d) => Number(d.id) === Number(id));
        if (match) {
          setData(mapToViewModel(match));
        } else {
          console.warn(`Match with id ${id} not found in matches.`);
        }
      } else {
        return;
      }
    };

    getUpdate();
  }, [datas, id]);
  const mapToViewModel = (match) => {
    return {
      id: match.id,
      round: match.round,
      matchnum: match.matchnum,
      homeclubID: match.homeClubID,
      homeGoal: match.homeGoal,
      awayclubID: match.awayClubID,
      awayGoal: match.awayGoal,
      date: match.date,
      time: match.time,
      status: match.status,
      stadium: match.stadium,
    };
  };

  const handleChange = ({ target: { name, value } }) => {
    setData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      doSubmit();
      toast.success("Data saved successfully!");
    } catch (error) {
      toast.error("An error occurred while saving data.");
    }
  };
  const socket = useMemo(() => io("https://api.youtopiaplc.com"), []);
  const doSubmit = () => {
    const updateData = {
      Id: id,
      data1: data,
    };
    socket.emit("dataUpdate", updateData, (response) => {
      if (response.success) {
        setDatas((prevDatas) => {
          return prevDatas.map((d) =>
            d.id === id ? { ...d, data1: data } : d
          );
        });
      } else {
        console.error("Failed to update message:", response.error);
        alert("Failed to edit message. Please try again.");
      }
    });
    navigate(`/liveData/${id}`);
  };

  const renderInput = (name, label, type = "text") => {
    return (
      <Input
        onChange={handleChange}
        label={label}
        type={type}
        name={name}
        value={data[name]}
      />
    );
  };
  const renderSelect = (name, label, options) => (
    <Select
      onChange={handleChange}
      label={label}
      options={options}
      name={name}
      value={data[name]}
    />
  );
  return (
    data && (
      <div className="livedata">
        <ToastContainer />
        <div className="live-btn">
          <button className="l-Btn" onClick={openModal1}>
            Goal
          </button>
          <button className="l-Btn" onClick={openModal2}>
            Red Card
          </button>
          <button className="l-Btn" onClick={openModal3}>
            Yellow Card
          </button>
          <Goalmodal isOpen={modalIsOpen1} closeModal={closeModal1} />
          <Yellowmodal isOpen={modalIsOpen3} closeModal={closeModal3} />
          <Redmodal isOpen={modalIsOpen2} closeModal={closeModal2} />
        </div>

        <div className="live">
          <form onSubmit={handleSubmit}>
            <div className="uploadColumn">
              <p>Home Team</p>
              <span></span>
              <p>Away Team</p>
              {renderInput("homeclubID", "Home ClubID")}
              <span>Club-ID</span>
              {renderInput("awayclubID", "Away ClubID")}
              {renderInput("homeGoal", "Home goal")}
              <span>Goal</span>
              {renderInput("awayGoal", "Away goal")}
            </div>
            <div className="uploadBottom">
              {renderInput("date", "Date")}
              {renderInput("time", "Time")}
              {renderSelect("status", "Status", ["Live", "FT"])}
              {renderInput("stadium", "Stadium")}
              {renderInput("matchnum", "Match number")}
            </div>
            <div className="uploadColumn">
              {renderInput("homeShots", "Home Shots")}
              <span>Shots</span>
              {renderInput("awayShots", "Away Shots")}
              {renderInput("homeTargetshots", "Home Target shots")}
              <span>Shots on target</span>
              {renderInput("awayTargetshots", "Away Target shots")}
              {renderInput("homePossession", "Home possession")}
              <span>Possession</span>
              {renderInput("awayPossession", "Away possession")}
              {renderInput("homeCorners", "Home Corners")}
              <span>Corners</span>
              {renderInput("awayCorners", "Away Corners")}
              {renderInput("homeOffside", "Home Offside")}
              <span>Offside</span>
              {renderInput("awayOffside", "Away Offside")}
              {renderInput("homePasses", "Home Passes")}
              <span>Passes</span>
              {renderInput("awayPasses", "Away Passes")}
              {renderInput("homeFouls", "Home Fouls")}
              <span>Fouls</span>
              {renderInput("awayFouls", "Away Fouls")}
            </div>
            <button className="formBtn" type="submit">
              Save
            </button>
          </form>
        </div>
      </div>
    )
  );
};

export default Live;
