import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { MyContext } from "../App";
import HomeTeam from "./homeTeam";
import AwayTeam from "./awayTeam";

const Formation = () => {
  const { id } = useParams() || "";
  const { Player, data, club } = useContext(MyContext);
  const [data1, setData] = useState({});
  useEffect(() => {
    const getUpdate = () => {
      if (id === "new") return;
      if (Array.isArray(data)) {
        const match = data.find((d) => Number(d.id) === Number(id));
        if (match) {
          setData(match);
        } else {
          console.warn(`Match with id ${id} not found in data.`);
        }
      } else {
        console.error("data is not an array or is undefined.");
      }
    };

    getUpdate();
  }, [data, id]);
  return (
    <div className="div-formation">
      <h1>Formation</h1>
      <div className="formation">
        <HomeTeam {...{ Player, data1, club, id }} />
        <AwayTeam {...{ Player, data1, club, id }} />
      </div>
    </div>
  );
};

export default Formation;
