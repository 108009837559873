import { useEffect, useState, useMemo } from "react";
import io from "socket.io-client";

export default function useSocketSetup() {
  const socket = useMemo(() => io("https://api.youtopiaplc.com"), []);
  const [datas, setDatas] = useState([]);

  useEffect(() => {
    socket.on("initialDatas", (data) => {
      setDatas(data);
    });
    socket.on("dataCreated", (data) => {
      setDatas((prevDatas) => [...prevDatas, data]);
    });
    socket.on("dataUpdated", (updatedData) => {
      setDatas((prevDatas) =>
        prevDatas.map((msg) => (msg.id === updatedData.id ? updatedData : msg))
      );
    });
    socket.on("dataDeleted", (id) => {
      setDatas((prevDatas) => prevDatas.filter((msg) => msg.id !== id));
    });
    return () => {
      socket.off("initialDatas");
      socket.off("dataCreated");
      socket.off("dataUpdated");
      socket.off("dataDeleted");
    };
  }, [socket]);

  return { datas, setDatas };
}
