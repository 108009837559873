import { useState } from "react";
import Joi from "joi-browser";
import { register } from "../services/footballData";
import Input from "./input";
import Select from "./select";

const Fixturedata = () => {
  const [data, setData] = useState({
    matchnum: "",
    round: "",
    homeclubID: "",
    homeGoal: "",
    awayclubID: "",
    awayGoal: "",
    date: "",
    time: "",
    status: "",
    stadium: "",
  });
  const [errors, setErrors] = useState({});
  const schema = {
    matchnum: Joi.number().required().label("Match number"),
    round: Joi.number().required().label("Round"),
    homeclubID: Joi.string().required().label("Home ClubID"),
    homeGoal: Joi.string().required().label("Home goal"),
    awayclubID: Joi.string().required().label("Away ClubID"),
    awayGoal: Joi.string().required().label("Away goal"),
    date: Joi.string().required().label("Date"),
    time: Joi.string().required().label("Time"),
    status: Joi.string().required().label("Status"),
    stadium: Joi.string().required().label("Stadium"),
  };
  const validate = () => {
    const option = { abortEarly: false };
    const { error } = Joi.validate(data, schema, option);
    if (!error) return null;
    const errors = {};
    error.details.map((item) => (errors[item.path[0]] = item.message));
    return errors;
  };
  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema1 = { [name]: schema[name] };
    const { error } = Joi.validate(obj, schema1);
    return error ? error.details[0].message : null;
  };
  const handleChange = ({ currentTarget: input }) => {
    const errors1 = { ...errors };
    const errorMessage = validateProperty(input);
    if (errorMessage) errors1[input.name] = errorMessage;
    else delete errors1[input.name];
    const data1 = { ...data };
    data1[input.name] = input.value;
    setData(data1);
    setErrors(errors1);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validate(data, schema);
    setErrors(errors || {});
    if (errors) return;
    doSubmit();
  };
  const doSubmit = async () => {
    await register(data);
    window.location = "/fixture";
  };

  const renderInput = (name, label, type = "text") => {
    return (
      <Input
        onChange={handleChange}
        label={label}
        type={type}
        name={name}
        value={data[name]}
        error={errors[name]}
      />
    );
  };
  const renderSelect = (name, label, options) => (
    <Select
      onChange={handleChange}
      label={label}
      options={options}
      name={name}
      value={data[name]}
    />
  );
  return (
    <div className="fixture">
      <form onSubmit={handleSubmit}>
        <div className="uploadColumn">
          <p>Home Team</p>
          <span></span>
          <p>Away Team</p>
          {renderInput("homeclubID", "Home ClubID")}
          <span>Club-ID</span>
          {renderInput("awayclubID", "Away ClubID")}
          {renderInput("homeGoal", "Home goal")}
          <span>Goal</span>
          {renderInput("awayGoal", "Away goal")}
        </div>
        <div className="uploadBottom">
          {renderInput("date", "Date")}
          {renderInput("time", "Time")}
          {renderSelect("status", "Status", ["NOT STARTED", "FT"])}
          {renderInput("stadium", "Stadium")}
          {renderInput("round", "Round")}
          {renderInput("matchnum", "Match number")}
        </div>

        <button className="formBtn" type="submit">
          Save
        </button>
      </form>
    </div>
  );
};

export default Fixturedata;
