import React, { createContext } from "react";
import { Routes, Route } from "react-router-dom";
import useData from "./routes/customHook/useData";
import useClub from "./routes/customHook/useClub";
import useNews from "./routes/customHook/useNews";
import usePlayers from "./routes/customHook/usePlayer";
import Initail from "./routes/initial";
import Live from "./routes/common/liveData";
import News from "./routes/common/news";
import SignUP from "./routes/createUser";
import Fixture from "./routes/fixture";
import Fixturefot from "./routes/common/fixturefot";
import Newspage from "./routes/newsPage";
import Changepass from "./routes/common/changepassword";
import Editnews from "./routes/common/editnews";
import Clubs from "./routes/clubs";
import Users from "./routes/users";
import Players from "./routes/player";
import Formation from "./routes/formation";
import "./App.css";
const MyContext = createContext();
const App = () => {
  const news = useNews();
  const data = useData();
  const club = useClub();
  const Player = usePlayers();

  return (
    <MyContext.Provider value={{ data, news, club, Player }}>
      <Routes>
        <Route path="/" element={<Initail />}>
          <Route index element={<Newspage />} />
          <Route path="news" element={<News />} />
          <Route path="users" element={<Users />}>
            <Route path="signup" element={<SignUP />} />
          </Route>
          <Route path="changepassword" element={<Changepass />} />
          <Route path="editnews/:id" element={<Editnews />} />
          <Route path="fixturefot" element={<Fixturefot />} />
          <Route path="liveData/:id" element={<Live />} />
          <Route path="formation/:id" element={<Formation />} />
          <Route path="fixture" element={<Fixture />} />
          <Route path="clubs" element={<Clubs />} />
          <Route path="player" element={<Players />} />
        </Route>
      </Routes>
    </MyContext.Provider>
  );
};

export { App, MyContext };
