import { useContext } from "react";
import { NavLink } from "react-router-dom";
import { MyContext } from "../../App";

const Fixturefot = () => {
  const { data, club } = useContext(MyContext);
  return (
    data &&
    data.map((d) => {
      const homeClub = club.find((c) => c.clubID === d.homeClubID);
      const awayClub = club.find((c) => c.clubID === d.awayClubID);
      return (
        <div key={d.id} className="tablefot">
          <button className="item1">
            {"Round - " + d.round} | Ethiopian Premier League
          </button>
          <div className="livediv">
            <div className="item2">
              {homeClub && (
                <>
                  <span className="leftName">{homeClub.clubname}</span>
                  <img
                    src={homeClub.url}
                    alt={`${homeClub.clubname} logo`}
                    className="clubLogo"
                  />
                </>
              )}
              <button className="time">{d.time}</button>
              {awayClub && (
                <>
                  <img
                    src={awayClub.url}
                    alt={`${awayClub.clubname} logo`}
                    className="clubLogo"
                  />
                  <span className="rightName">{awayClub.clubname}</span>
                </>
              )}
              <NavLink to={`/formation/${d.id}`}>formation</NavLink>
              <NavLink to={`/liveData/${d.id}`}>Live</NavLink>
            </div>
          </div>
        </div>
      );
    })
  );
};

export default Fixturefot;
