import { useState } from "react";
import { registerFormation } from "./services/footballData";

const AwayTeam = ({ Player, data1, club }) => {
  const [selectedPlayers, setSelectedPlayers] = useState({});
  const [playerStates, setPlayerStates] = useState({}); // Stores isSubs states
  const awayClub = data1 && club.find((c) => c.clubID === data1.awayClubID);
  const awayPlayers = Player.filter((p) => p.club === awayClub?.clubname);

  const handleCheckboxChange = (playerID) => {
    setSelectedPlayers((prev) => ({
      ...prev,
      [playerID]: !prev[playerID],
    }));
  };

  const handleSubChange = (playerID, isSub) => {
    setPlayerStates((prev) => ({
      ...prev,
      [playerID]: isSub,
    }));
  };

  const handleSubmit = async () => {
    const matchnum = data1.matchnum;
    const selectedData = Object.keys(selectedPlayers)
      .filter((playerID) => selectedPlayers[playerID])
      .map((playerID) => {
        const player = Player.find((p) => p.playerID === playerID);
        return {
          playerID,
          matchnum,
          clubname: player.club || "Unknown",
          isSubstitute: playerStates[playerID] || false,
        };
      });

    try {
      await registerFormation(selectedData);
    } catch (error) {
      console.log(error);
    }
  };

  const renderPlayers = (players, teamType) => (
    <div>
      <h2>{teamType} Team</h2>
      {players.map((p) => (
        <div key={p.playerID} className="homePlayer">
          <span>{p.number}</span>
          <span>{p.position}</span>
          <span>{p.name}</span>
          <span> (ID: {p.playerID})</span>
          <span> - {p.club}</span>
          <select
            value={playerStates[p.playerID] || "false"}
            onChange={(e) =>
              handleSubChange(p.playerID, e.target.value === "true")
            }
          >
            <option value="false">Starter</option>
            <option value="true">Substitute</option>
          </select>
          <input
            type="checkbox"
            checked={!!selectedPlayers[p.playerID]}
            onChange={() => handleCheckboxChange(p.playerID)}
          />
        </div>
      ))}
    </div>
  );

  return (
    <div className="clubsForm">
      {renderPlayers(awayPlayers, "Away")}
      <button onClick={handleSubmit}>Submit </button>
    </div>
  );
};

export default AwayTeam;
