import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { MyContext } from "../../App";
import Input from "./input";
import TextArea from "./textArea";
import config from "../../config.json";
import { ToastContainer, toast } from "react-toastify";

const apiNews = config.apiUrl + "/news";
const Editnews = () => {
  const { news } = useContext(MyContext);
  const { id } = useParams();
  const [data, setData] = useState({
    headline: "",
    author: "",
    catagory: "",
    content: "",
    imgurl: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const match = news.find((m) => m.id === Number(id));
        if (!match) {
          throw new Error(`News with id ${id} not found.`);
        }
        console.log(match);
        setData(mapToViewModel(match));
      } catch (error) {
        alert("Error fetching news:", error);
      }
    };
    fetchData();
  }, [news, id]);
  const mapToViewModel = (match) => ({
    id: match.id,
    headline: match.headline,
    author: match.author,
    content: match.content,
    catagory: match.catagory,
    imgurl: match.imgurl,
  });

  const [file, setFile] = useState(null);
  const handleDataChange = ({ target: { name, value } }) => {
    const news = { ...data };
    news[name] = value;
    setData(news);
  };
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("headline", data.headline);
      formData.append("author", data.author);
      formData.append("catagory", data.catagory);
      formData.append("content", data.content);
      formData.append("file", file);
      await axios.put(apiNews + "/" + id, formData);
      toast.success("File uploaded successfully!");
    } catch (error) {
      toast.error("Error uploading file. Please try again.");
    }
  };
  const renderInput = (name, label, type = "text") => {
    return (
      <Input
        onChange={handleDataChange}
        label={label}
        type={type}
        name={name}
        value={data[name]}
      />
    );
  };
  const renderTextarea = (name, label, type = "text") => {
    return (
      <TextArea
        onChange={handleDataChange}
        label={label}
        type={type}
        name={name}
        value={data[name]}
      />
    );
  };
  return (
    news && (
      <div className="news">
        <ToastContainer position="top-right" autoClose={3000} />
        <div className="card">
          <img src={data.imgurl} alt="news logo" />
        </div>
        <form
          onSubmit={handleFormSubmit}
          encType="multipart/form-data"
          className="picForm"
        >
          {renderInput("headline", "Headline")}
          {renderInput("catagory", "Catagory")}
          {renderTextarea("content", "Content")}
          {renderInput("author", "Author")}
          <label htmlFor="file" className="custom-file-upload">
            {file ? file.name : "Upload header image"}
          </label>
          <input
            className="file-upload-input"
            type="file"
            name="file"
            id="file"
            accept="image/*"
            onChange={handleFileChange}
          />
          <button className="upload-button" type="submit">
            Save
          </button>
        </form>
      </div>
    )
  );
};

export default Editnews;
