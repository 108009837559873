import { useEffect, useState } from "react";
import { getPlayers } from "../services/footballData";

export default function usePlayers() {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await getPlayers();
        if (!response || !response.data) {
          throw new Error("Failed to fetch initial player data");
        }
        setData(response.data);
      } catch (error) {
        console.error("Error fetching initial player data:", error);
      }
    };
    fetchInitialData();
  }, []);

  return data;
}
