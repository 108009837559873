import { useContext } from "react";
import useSocketSetup from "./customHook/useSocketSetup";
import { MyContext } from "../App";

const Livecomp = () => {
  const { datas } = useSocketSetup();
  const { club } = useContext(MyContext);
  if (!datas || !club) {
    return null;
  }
  const arr = datas || [];
  const liveMatch = arr.filter((match) => match.status === "Live");

  return liveMatch.map((m) => {
    const homeClub = club.find((c) => c.clubID === m.homeClubID);
    const awayClub = club.find((c) => c.clubID === m.awayClubID);
    return (
      <div className="live-match" key={m.id}>
        <div className="live1">
          {homeClub && <span>{homeClub.clubname}</span>}
          <span>{m.homeGoal}</span>
          <button className="live-status">{m.status}</button>
          <span>{m.awayGoal}</span>
          {awayClub && <span>{awayClub.clubname}</span>}
        </div>
      </div>
    );
  });
};

export default Livecomp;
