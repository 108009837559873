import { useContext } from "react";
import { MyContext } from "../../App";
import { ToastContainer, toast } from "react-toastify";
import { DeleteClub } from "../services/footballData";

const Logourl = () => {
  const { club } = useContext(MyContext);
  const handleDelete = async (id) => {
    try {
      await DeleteClub(id);
      toast.success("File deleted successfully!");
    } catch (error) {
      toast.error("Error uploading file. Please try again.");
    }
  };
  return (
    <div className="url">
      <ToastContainer position="top-right" autoClose={3000} />
      <table>
        <tbody>
          {club &&
            club.map((m) => (
              <tr key={m.id}>
                <td>{m.clubname}</td>
                <td>{m.clubID}</td>
                <td>
                  <button
                    className="btn-delete"
                    onClick={() => handleDelete(m.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default Logourl;
