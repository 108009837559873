import { useEffect, useState } from "react";
import { getDatas } from "../services/footballData";

export default function useNews() {
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await getDatas();
        if (!response || !response.data) {
          throw new Error("Failed to fetch initial data");
        }
        const initialData = response.data.reverse();
        setData(initialData);
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };

    fetchInitialData();
  }, []);

  return data;
}
