import { useEffect, useState } from "react";
import { getClubs } from "../services/footballData";

export default function useLogo() {
  const [data, setData] = useState();

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getClubs();
        if (!response || !response.data) {
          throw new Error("Failed to fetch initial news data");
        }
        setData(response.data);
      } catch (error) {}
    };
    getData();
  }, []);
  return data;
}
