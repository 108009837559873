import { useEffect, useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import { getDatas, Delete } from "./services/userService";

const Users = () => {
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(true); // For loading state
  const [error, setError] = useState(null); // For error handling

  useEffect(() => {
    const getUser = async () => {
      try {
        const { data } = await getDatas();
        setUser(data);
      } catch (error) {
        setError("Error fetching data. Please try again later.");
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Stop loading state once request completes
      }
    };
    getUser();
  }, []);

  const handleDelete = async (id) => {
    try {
      await Delete(id);
      setUser(user.filter((u) => u.id !== id)); // Update UI after deletion
    } catch (error) {
      console.log(error);
      setError("Error deleting user. Please try again later.");
      console.error("Error deleting user:", error);
    }
  };

  if (loading) {
    return <p>Loading users...</p>; // Loading message
  }

  return (
    <div>
      <h3>Users</h3>
      {error && <p style={{ color: "red" }}>{error}</p>} {/* Error message */}
      {user && user.length > 0 ? (
        user.map((u) => (
          <div key={u.id} className="user">
            <p>{u.username}</p>
            <p>{u.phone}</p>
            <button onClick={() => handleDelete(u.id)}>Delete</button>
          </div>
        ))
      ) : (
        <p>No users available.</p> // Message when no users are present
      )}
      <NavLink to="signup">Create new user</NavLink>
      <Outlet />
    </div>
  );
};

export default Users;
